<template>
  <div>
    <el-form :model="formItem" :rules="rules" ref="formItem" label-width="200px">
      <el-form-item :label="$t('setPerson.password')" prop="password">
        <el-input v-model="formItem.password" show-password type="password" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('setPerson.newPassword')" prop="newpassword">
        <el-input v-model="formItem.newpassword" show-password type="password" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('setPerson.confirmPassword')" prop="confirmpassword">
        <el-input v-model="formItem.confirmpassword" show-password type="password" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('formItem')" plain>提交</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
export default {
  mixins: [mixin(), propValue()],
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.formItem.confirmpassword !== '') {
          this.$refs.formItem.validateField('confirmpassword')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.formItem.newpassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      formItem: {
        password: '',
        newpassword: '',
        confirmpassword: ''
      },
      rules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        newpassword: [
          { validator: validatePass, trigger: 'blur' }
        ],
        confirmpassword: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      dialogImageUrl: '',
      dialogVisible: false,
      loading: false
    }
  },
  methods: {
    onSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$msgbox({
            title: this.$t('normal.tip'),
            message: this.$t('normal.tipDes'),
            confirmButtonText: this.$t('button.confirm'),
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true
                instance.confirmButtonText = this.$t('normal.process')
                instance.title = this.$t('normal.processTitle')
                instance.message = this.$t('normal.processMessage')
                setTimeout(() => {
                  done()
                  setTimeout(() => {
                    instance.confirmButtonLoading = false
                  }, 300)
                }, 1000)
              } else {
                done()
              }
            }
          }).then(action => {
            if (action === 'confirm') {
              this.postFetch('/api/user/setPerson', this.formItem, { needSuccess: true }).then((response) => {
                // this.$router.replace('/' + this.controller + '/index')
              })
            }
          })
        } else {
          this.$message({
            message: this.$t('normal.validata'),
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
